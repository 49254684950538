@import '_variable.scss';
@import '_unity.scss';
/**
 * -----
 * custom override commonhealth style from lodestar app 
 * -----
 */

header .d-flex .ant-btn {
  color: #222;
}

.profile-box {
  display: flex;
  align-items: center;
  .profile {
    overflow: hidden;
    border-radius: 100%;
    min-width: 32px;
    width: 32px;
    height: 32px;
    margin-right: 10px;
    img {
      width: 100%;
    }
  }
}

.container {
  &.medium {
    max-width: 920px;
  }
}

h2 {
  &.title {
    font-size: 22px;
    font-weight: bold;
    @media (max-width: 992px) {
      margin: 10px 0;
    }
  }
}

a {
  &.primary {
    color: map-get($color, primary);
    text-decoration: underline;
  }
  &.gap {
    margin: 0 5px;
  }
}
// -----
// custom chakra-button
// -----
button {
  &.chakra-button {
    border-radius: 22px !important;
    @media (max-width: 450px) {
      padding-left: 0.7rem;
      padding-right: 0.7rem;
    }
    &.bg-primary {
      background-color: map-get($color, primary);
      color: map-get($color, white);
      &:hover {
        background-color: map-get($color, primaryDarken) !important;
      }
    }
    &.cat-active {
      border: 1px solid map-get($color, primary);
    }
    &.cat-no-active {
      border: 1px solid map-get($color, lightgray);
      background-color: map-get($color, white);
      color: map-get($color, primary);
    }
    &.border-button {
      border: 1px solid map-get($color, primary);
      color: map-get($color, primary);
      &:hover {
        border: 1px solid map-get($color, primaryDarken);
        color: map-get($color, primaryDarken);
      }
    }
    &.onsale-full {
      background-color: map-get($color, success);
      &:hover {
        background-color: map-get($color, successDarken);
      }
    }
    &.onsale-border {
      background-color: map-get($color, white);
      border-color: map-get($color, success);
      color: map-get($color, success);
      &:hover {
        border-color: map-get($color, successDarken);
        color: map-get($color, successDarken);
      }
    }
    &:disabled {
      background-color: #f7f7f7;
      color: #d1d1d1;
      border: 1px solid #d1d1d1;
    }
    &.no-rounded {
      border-radius: 2px !important;
    }
  }
  &.chakra-modal__close-btn {
    right: 0.75rem;
  }
}

.button {
  border-radius: 22px;
  margin: auto;
  transition: all 0.3s;
  &.login {
    height: 32px;
    padding: 0 15px;
  }
  &.border-primary {
    border: 1px solid map-get($color, primary);
  }
  &.default {
    width: 100%;
    padding: 10px;
    max-width: 180px;
  }
  &.bg-primary:hover {
    background-color: map-get($color, primaryDarken) !important;
  }
}

.success-price {
  font-size: 20px !important;
  color: map-get($color, success);
}

.page-section {
  padding-top: 40px;
}

.contact-icon {
  width: 1em;
  height: 1em;
  display: inline-block;
  line-height: 1em;
  flex-shrink: 0;
}

// -----
// program page setting
// -----
.list-section {
  > div {
    transition: all 0.3s;
    &:hover {
      background-color: $primaryLightHover;
    }
  }
}

// -----
// antd customize
// -----
.ant-tag {
  &.secondary {
    background-color: map-get($color, secondary) !important;
  }
}

.ant-btn:not(.ant-btn-link) {
  border-radius: 22px !important;
  border: 1px solid map-get($color, primary) !important;
  &:not(.ant-btn-primary) {
    color: map-get($color, primary) !important;
    &:hover {
      color: map-get($color, primaryDarken) !important;
      border: 1px solid map-get($color, primaryDarken) !important;
    }
  }
  &.ant-btn-primary {
    background-color: map-get($color, primary) !important;
    color: map-get($color, white) !important;
    &:hover {
      background-color: map-get($color, primaryDarken) !important;
      border: 1px solid map-get($color, primaryDarken) !important;
    }
  }
}
header .ant-btn:not(.login):hover {
  border-color: transparent !important;
}
.ant-select-selection,
.ant-btn:not(.ant-btn-link),
.ant-input {
  &:hover {
    border-color: map-get($color, primary) !important;
  }
  &:focus {
    border-color: map-get($color, primary);
    box-shadow: 0 0 0 2px rgba(0, 153, 68, 0.2) !important;
  }
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: map-get($color, primary) !important;
  border-color: map-get($color, primary) !important;
}

// ant notice message
.ant-message-notice {
  .ant-message-notice-content {
    padding: 0 !important;
    border-radius: 4px !important;
    .ant-message-custom-content {
      padding: 10px 16px !important;
      &.ant-message-success {
        border: 1px solid map-get($color, primary) !important;
        color: map-get($color, primary) !important;
      }
      &.ant-message-error {
        border: 1px solid map-get($color, danger) !important;
        color: map-get($color, danger) !important;
      }
      &.ant-message-warning {
        border: 1px solid map-get($color, success) !important;
        color: map-get($color, success) !important;
      }
    }
  }
}

// ant question panel
.ant-tabs-ink-bar {
  background-color: map-get($color, primary) !important;
}
.ant-tabs-nav .ant-tabs-tab {
  &:hover {
    color: map-get($color, primary) !important;
  }
  &.ant-tabs-tab-active {
    color: map-get($color, primary) !important;
    &:hover {
      color: map-get($color, primaryDarken) !important;
    }
  }
}

// personal setting page
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background-color: map-get($color, primaryLighten) !important;
}

.ant-menu-item {
  color: map-get($color, black);
  &.ant-menu-item-selected {
    color: map-get($color, black);
    background-color: rgba(0, 153, 68, 0.1) !important;
    &:after {
      border-right: 3px solid map-get($color, primary);
    }
  }
}

.ant-select-dropdown-menu-item-selected {
  background-color: rgba(0, 153, 68, 0.1) !important;
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: rgba(0, 153, 68, 0.1) !important;
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: map-get($color, primary);
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 153, 68, 0.2);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: map-get($color, primary) !important;
}
.ant-checkbox-checked::after {
  border-color: map-get($color, primary) !important;
}

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background-color: $primaryLightHover !important;
}

// dropdown menu
.ant-dropdown-menu {
  li:hover {
    background-color: $primaryLightHover !important;
    color: map-get($color, primary) !important;
  }
}

// custom ant mobile
.custom-ant-typography {
  @media (max-width: 992px) {
    width: 70%;
  }
}

// -----
// slick cusomize
// -----
.slick-dots {
  li {
    button:before {
      color: map-get($color, lightgray) !important;
    }
    &.slick-active {
      button::before {
        color: map-get($color, primary) !important;
      }
    }
  }
}

// slick arrow
.slick-prev {
  height: auto;
  &:before {
    content: '';
    display: block;
    background-image: url(../images//arrow-left.svg);
    background-repeat: no-repeat;
    background-position: center center;
    width: 25px;
    height: 40px;
  }
  @media (max-width: 992px) {
    left: 25px;
    &:before {
      background-image: url(../images//arrow-left@dark.svg);
    }
  }
}
.slick-next {
  height: auto;
  &:before {
    content: '';
    display: block;
    background-image: url(../images//arrow-right.svg);
    background-repeat: no-repeat;
    background-position: center center;
    width: 20px;
    height: 40px;
  }
  @media (max-width: 992px) {
    right: 25px;
    &:before {
      background-image: url(../images//arrow-right@dark.svg);
    }
  }
}
$color: (
  primary: #009944,
  primaryLighten: #1ab35e,
  primaryDarken: #00802b,
  secondary: #54b782,
  secondaryDarken: #3b9e69,
  success: #ef8200,
  successDarken: #d66900,
  danger: #e10700,
  warninig: #9c5faf,
  info: #58bfff,
  lightgray: #e8e8e8,
  white: #fff,
  black: #222,
);
// primary rgba rgba(0, 153, 68, 1)
// hover light primary rgba(0, 153, 68, 0.1)
$primaryLightHover: rgba(0, 153, 68, 0.1);
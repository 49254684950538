@import '_variable.scss';
// -----
// font weight
// -----
.fw-regular {
  font-weight: 400 !important;
}
.fw-medium {
  font-weight: 500 !important;
}
.fw-bold {
  font-weight: 700 !important;
}
// -----
// font color
// -----
.fc-primary {
  color: map-get($color, primary) !important;
}
.fc-secondary {
  color: map-get($color, secondary) !important;
}
.fc-success {
  color: map-get($color, success) !important;
}
.fc-danger {
  color: map-get($color, danger) !important;
}
.fc-lightgray {
  color: map-get($color, lightgray) !important;
}
.fc-white {
  color: map-get($color, white) !important;
}
.fc-black {
  color: map-get($color, black) !important;
}
// -----
// background color
// -----
.bg-primary {
  background-color: map-get($color, primary) !important;
}
.bg-secondary {
  background-color: map-get($color, secondary) !important;
}
.bg-success {
  background-color: map-get($color, success) !important;
}
.bg-danger {
  background-color: map-get($color, danger) !important;
}
.bg-lightgray {
  background-color: map-get($color, lightgray) !important;
}
.bg-white {
  background-color: map-get($color, white) !important;
}
.bg-black {
  background-color: map-get($color, black) !important;
}

// -----
// border none
// -----
.border-none {
  border: none !important;
}

.block-gap {
  &.large {
    margin-bottom: 90px;
    @media (max-width: 992px) {
      margin-bottom: 50px;
    }
  }
}

.hide-icon {
  svg {
    display: none;
  }
}